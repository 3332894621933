<template>
  <section class="section">
    <h1>统计分析</h1>
  </section>
</template>

<script>
export default {
  name: '',
  props: {},
  components: {},
  data() {
    return {};
  },
  methods: {},
  mounted() {},
  created() {},
  computed: {},
  watch: {},
};
</script>

<style scoped lang="scss">
</style>
